/* Base styles */

.testimonial-heading {
    font-weight: 800;
}

.horizontal-hr-testimonial {
    height: 4px;
    width: 10%;
    background-color: var(--yellow-color);
    border-radius: 20px;
}

.testimonials-peragraph {
    width: 60%;
    text-align: center;
}

.testimonial-cards-item {
    width: 100%;
    min-height: 40vh;
    height: auto;
    max-height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding: 10px 20px;
}

.testimonial-cards-item .testimonial-cards-top-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(to top, rgb(229, 226, 226), rgb(226, 225, 225), white);
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.testimonial-cards-item .testimonial-cards-top-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    object-position: top;
}

.profession-here {
    opacity: .5;
}

/* Responsive styles */

@media screen and (max-width: 768px) {
    .testimonials-peragraph {
        width: 90%;
    }

    .testimonial-cards-item {
        padding: 10px;
    }

    .testimonial-cards-item .testimonial-cards-top-img {
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 480px) {
    .testimonial-cards-item .testimonial-cards-top-img {
        width: 80px;
        height: 80px;
    }
}
