.affliate-page-earning-pesa {
    width: 100%;
    min-height: 20vh;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.affliate-page-earning-pesa .affliate-page-earning-pesa-items {
    width: 100%; /* Full width on smaller screens */
    max-width: 33.33%; /* Maximum width on larger screens */
    height: 50%; /* 50% height on smaller screens */
    padding: 10px;
    box-sizing: border-box; /* Include padding in the width */
}

.affliate-page-earning-pesa .affliate-page-earning-pesa-items .affliate-page-earning-pesa-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
