.parent-registration-progress{
    width: 100%;
    height: 8vh;
    overflow: hidden;
}
.parent-registration-progress>input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    background-color: #0D131D;
    color: wheat;
    border-radius: 7px;
    box-shadow: 2px 3px 10px black;
}
.parent-registration-progress>input::placeholder{
    color: wheat;
}

label{
    text-transform: capitalize;
}

.step-container{
    display: flex;
    align-items: center;
    width: 100%;
}

.progress-line{
    width: calc(100% - 40px);
    height: 3px;
    background-color: red;
    position: relative !important;
}

.step-box{
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
}
.drop-icon{
    >svg{
        font-size: 42px;
        color: blue;
    }
}

.registration-status-here{
    background-color: green !important;
}