/* Common styles */
.operation-role-and-permission-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.table-container-operations {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  gap: 3px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.table-container-operations-item-td {
  flex: 1;
  min-width: 140px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-container-operations.yellow-bottom {
  border-bottom: 3px solid #f5d575;
}

.table-container-operations.grey-bottom {
  border-bottom: 3px solid grey;
}

.table-container-operations .table-container-operations-item-td .content-operation {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.parent-operation-container {
  overflow-x: auto;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .table-container-operations{
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    gap: 10px;
    padding: 10px ;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .table-container-operations.yellow-bottom {
    border: 3px solid #f5d575;
  }
  
  .table-container-operations.grey-bottom {
    border: 3px solid grey;
  }

  .table-container-operations .table-container-operations-item-td {
    width: auto;
    min-width: 120px;
  }

  .parent-operation-container {
    overflow-x: scroll;
  }

  .table-container-operations {
    flex-wrap: nowrap;
  }

  .table-container-operations-item-td {
    width: auto;
  }
}
