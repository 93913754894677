.home-work-parent-search-area{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 7px 10px;
    border-radius: 25px;
    border: 1.3px solid grey;
}
.home-work-parent-search-area input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 10px;
}

.home-work-switch-button {
    color: black  !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.home-work-switch-button.home-work-switch-button-active{
    border-bottom: 2px solid grey !important;
    border-radius: 25px !important;
}

.home-work-point{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.home-work-point span{
    font-size: large;
}

.homework-parent-tab-responsive{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .homework-parent-tab-responsive{
        display: block !important;
    }
}