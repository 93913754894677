.underRevie {
  .col-L {
    padding: 2em;
  }
  .col-R {
    max-width: 50%;
    width: 50%;
  }
  .logo {
    height: 40px;
    width: 40px;
    font-size: 0.5em;
    background-color: #ffcb1f;
    border-radius: 50%;
    padding: 1em;
    margin: 1em;
  }
  .st0 {
    fill: #ffcb1e;
  }
  .st1 {
    fill: #ffb8b8;
  }
  .st2,
  .st4,
  .st7 {
    stroke: #3f0e8c;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill: none;
  }
  .st3 {
    fill: #ffffff;
    stroke: #1d1d1b;
    stroke-width: 0.9847;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: #00d292;
  }
  .st5 {
    fill: #00d292;
  }
  .st6 {
    fill: #00cccb;
  }
  .st7 {
    fill: #00cccb;
  }
  .st8 {
    fill: none;
    stroke: #1d1d1b;
    stroke-width: 0.9847;
    stroke-miterlimit: 10;
  }
  .st4,
  .st5,
  .st6 {
    -webkit-animation: downMotion 8s ease-in-out infinite;
    -moz-animation: downMotion 8s ease-in-out infinite;
    animation: downMotion 8s ease-in-out infinite;
  }
  .st0 {
    -webkit-animation: upMotion 10s ease-in-out infinite;
    -moz-animation: upMotion 10s ease-in-out infinite;
    animation: upMotion 10s ease-in-out infinite;
  }
  .st7,
  .run {
    -webkit-animation: upMotion 6s ease-in-out infinite;
    -moz-animation: upMotion 6s ease-in-out infinite;
    animation: upMotion 6s ease-in-out infinite;
  }
  .rotate {
    transform-origin: 116px 88px;
    -webkit-animation: 4s rotate infinite linear;
    animation: 4s rotate infinite linear;
  }
  @keyframes downMotion {
    0% {
      transform: translate(0, -15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -15px);
    }
  }
  @-webkit-keyframes downMotion {
    0% {
      transform: translate(0, -15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -15px);
    }
  }
  @-moz-keyframes downMotion {
    0% {
      transform: translate(0, -15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, -15px);
    }
  }
  @keyframes upMotion {
    0% {
      transform: translate(0, 15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 15px);
    }
  }
  @-webkit-keyframes upMotion {
    0% {
      transform: translate(0, 15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 15px);
    }
  }
  @-moz-keyframes upMotion {
    0% {
      transform: translate(0, 15px);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 15px);
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-o-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @media only screen and (min-width: 992px) {
    .container {
      justify-content: center;
      height: calc(100vh - 16px);
      display: flex;
      flex-wrap: wrap;
    }
    .col-L {
      width: 40%;
      margin-right: 10%;
    }
  }
  @media only screen and (max-width: 991px) {
    .flex-column {
      flex-direction: column;
    }
  }
}
