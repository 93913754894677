@media screen and (max-width: 679px) {
    .reshedule-display-none-on-mobile {
        display: none;
    }
}

/* Hidden on screens between 679px and 1024px (tablets and laptops) */
@media screen and (min-width: 679px) and (max-width: 1024px) {
    .reshedule-display-none-on-laptop-desktop {
        display: none;
    }
}

/* Hidden on screens larger than 1024px (desktops) */
@media screen and (min-width: 1025px) {
    .reshedule-display-none-on-laptop-desktop {
        display: none;
    }
}
