.FeedBack-Container{
    height: 40vh;
}

.FeedBack-page-heading-1{
    font-weight: 800;
    color: var(--color-primary);
    font-size: 42px;
}
.FeedBack-page-heading-2{
    font-weight: 700;
    color: black;
    font-size: 32px;
}
.rating-question-feed-back-container{
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    >p{
        text-align: center;
    }
}
.rating-question-feed-back-container.rating-question-feed-back-auto{
    height: auto !important;
}
.feed-back-user-stand{
    height: 50vh;
}

.FeedBackStarGroup{
    width: 130px;
}

.rating-feed-dark{
    color: var(--color-primary);
}