.top-payment-tab-action {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.top-payment-tab-items.active-payment-parent-tab {
  border-bottom: 2px solid var(--yellow-color);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  transition: all 0.5s ease;
  background: #f7f3e9;
}
.top-payment-tab-items.active-payment-parent-tab.another {
  border-bottom-color: var(--yellow-color) !important;
  transition: all 0.5s ease;
}

.top-payment-tab-items {
  width: calc(100% / 5);
  height: 7vh;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.top-payment-tab-items:hover {
  border-bottom: 2px solid var(--yellow-color);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  cursor: pointer;
}
.top-payment-tab-items.another:hover {
  border-bottom-color: var(--yellow-color) !important;
}

.payment-parent-container {
  height: auto;
  border-radius: 10px;
  box-shadow: 1px 2px 10px rgb(171, 171, 122);
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.payment-parent-container .payment-parent-container-navbar {
  /* width: 100%; */
  height: 10vh;
  padding: 0px 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.678);
  background: var(--yellow-color);
}
.payment-parent-container
  .payment-parent-container-navbar
  .bank-details-title-payment {
  font-weight: bolder;
  font-size: 16px;
}

.responsive-parent-payment-check-chat-button {
  display: flex;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 768px) {
  .top-payment-tab-items {
    width: 50vw;
  }

  .top-payment-tab-action {
    margin-top: 30px;
    overflow-x: hidden;
  }

  .responsive-parent-payment-check-chat-button {
    flex-wrap: wrap;
  }
  .parent-payment-account-details-button-responsive {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0px;
  }
  .payment-parent-container .payment-parent-container-navbar {
    min-height: 10vh;
    height: auto;
  }
  .responsive-box-hidden-for-copy {
    width: 100%;
    height: 10vh;
  }
}
