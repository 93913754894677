.donate-tesmonial-body {
  width: 100%;
  height: auto;
  border-radius: 7px;
  padding: 10px 20px;
}

.donate-tesmonial-body .donate-text-icon-area.donate-text-icon-area-1 {
  background: linear-gradient(rgb(152, 134, 255), rgb(212, 202, 255));
}

.donate-tesmonial-body .donate-text-icon-area.donate-text-icon-area-2 {
  background: linear-gradient(rgb(255, 184, 85), rgb(255, 222, 175));
}

.donate-tesmonial-body .donate-text-icon-area.donate-text-icon-area-3 {
  background: linear-gradient(rgb(255, 132, 220), rgb(255, 211, 243));
}

.donate-tesmonial-body .donate-text-icon-area {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  > svg {
    color: rgb(255, 255, 255);
    font-size: 30px;
  }
}
