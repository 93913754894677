@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-primary: #5751e1;
  --color-secondary: #f6f6f6;
  --color-primary-light: #f7ff62;
  --color-dark: #1f1d0d;
  --color-white: #fff;
  --yellow-color: #ffc224;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif !important;
  /* overflow-x: hidden; */
}

.custom-table td,
.custom-table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-popppins {
  font-family: "Poppins", sans-serif !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-yellow {
  color: var(--yellow-color);
}

.mouse {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #050071;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  pointer-events: none;
}

.search-dropdown {
  border-color: var(--color-primary) !important;
}

.text_yellow {
  color: var(--color-primary);
}

.bg-yellow {
  background-color: var(--color-primary) !important;
}

.small_text {
  font-size: 12px;
}

.line_height {
  line-height: 1;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.text_stoke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
}

input,
textarea {
  outline: none;
}

.C_drop {
  .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .dropdown-menu.show {
    width: 100% !important;
  }
}

.myswiper_hero .swiper-slide {
  background: white !important;
  /* Or any other solid color */
  z-index: 1 !important;
}

.myswiper_hero .swiper-slide-prev,
.myswiper_hero .swiper-slide-next {
  visibility: hidden !important;
}

.cancel_btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border: 2px solid var(--color-primary);
  padding: 7px 50px;
  border-radius: 100px;
  color: var(--color-primary);
  font-weight: 600;
}

.reschedule_btn {
  background-color: var(--color-primary);
  border: none;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 7px 50px;
  border-radius: 100px;
  font-weight: 600;
}

.border-yellow {
  border: 1px solid var(--color-primary) !important;
  outline: none !important;

  > svg {
    color: var(--color-primary);
  }
}

@media (max-width: 768px) {
  .mouse {
    display: none;
  }

  .employeeHistoryResponsive {
    width: 100% !important;
  }

  .employeeHistoryResponsive-details {
    width: 100% !important;
    text-align: justify;
  }
}

li.circle-list {
  list-style: circle !important;
}

li.number-list {
  list-style: decimal !important;
}

li.alfabet-list {
  list-style: upper-alpha !important;
}

.table {
  --bs-table-bg: transparent !important;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.black-outline-button {
  border-color: #1f1d0d !important;
  color: #1f1d0d !important;
}

.admin_pro > .dropdown-toggle::after {
  display: none !important;
}

.admin_pro .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent !important;
}

/* ============================= LOADER CSS ============================= */

.ring {
  position: absolute;
  height: 200px;
  width: 200px;
  border: 0px solid #272727;
  border-radius: 50%;
}

.ring:nth-child(1) {
  border-bottom-width: 10px;
  border-color: var(--color-primary);
  animation: rotate1 1.5s linear infinite;
}

.ring:nth-child(2) {
  border-right-width: 10px;
  border-color: var(--yellow-color);
  animation: rotate2 1.5s linear infinite;
}

.ring:nth-child(3) {
  border-top-width: 10px;
  border-color: #050071;
  animation: rotate3 1.5s linear infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.emp_mngmnt-drop .dropdown-toggle::after {
  display: none !important;
}

.arrow-btn {
  width: fit-content;
  --arrow-hover-move-x: -110% !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 10px !important;
  transition: 0.3s linear !important;
  user-select: none !important;
  -moz-user-select: none !important;
  background: var(--color-primary) none repeat scroll 0 0 !important;
  border: medium none !important;
  color: white !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 1.12 !important;
  margin-bottom: 0 !important;
  padding: 16px 30px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  touch-action: manipulation !important;
  -webkit-transition: all 0.3s ease-out 0s !important;
  -moz-transition: all 0.3s ease-out 0s !important;
  -ms-transition: all 0.3s ease-out 0s !important;
  -o-transition: all 0.3s ease-out 0s !important;
  transition: all 0.3s ease-out 0s !important;
  vertical-align: middle !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -o-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  border-radius: 50px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  box-shadow: 4px 6px 0px 0px #050071 !important;

  > svg {
    color: white !important;
    width: 15px !important;
    transition: all 0.3s ease-out 0s !important;
    transform: translateY(-1px) !important;
    margin-left: 7px !important;

    path {
      transition: transform 0.38s cubic-bezier(0.37, 0.08, 0.02, 0.93),
        opacity 0.18s ease-out !important;
    }

    path:nth-of-type(1) {
      transform: translateX(0) !important;
      opacity: 1 !important;
      transition-delay: 0.15s, 0.15s !important;
    }

    path:nth-of-type(2) {
      transform: translateX(calc(1 * var(--arrow-hover-move-x))) !important;
      opacity: 0.5 !important;
      transition-delay: 0s, 0s !important;
    }
  }

  &:focus-visible svg path:nth-of-type(1) {
    transform: translateX(calc(-1 * (-110%))) !important;
    opacity: 0 !important;
    transition-delay: 0s, 0s !important;
  }

  &:hover svg path:nth-of-type(1) {
    transform: translateX(calc(-1 * (-110%))) !important;
    opacity: 0 !important;
    transition-delay: 0s, 0s !important;
  }

  &:focus-visible svg path:nth-of-type(2) {
    transform: translateX(0) translateY(0) !important;
    opacity: 1 !important;
    transition-delay: 0.15s, 0.15s !important;
  }

  &:hover svg path:nth-of-type(2) {
    transform: translateX(0) translateY(0) !important;
    opacity: 1 !important;
    transition-delay: 0.15s, 0.15s !important;
  }

  &:hover {
    background: var(--yellow-color) !important;
    box-shadow: 0px 0px 0px #050071 !important;
  }
}

.swiper-pagination-bullet {
  display: inline-block !important;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%) !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2) !important;
  height: 12px !important;
  width: 12px !important;
  background: transparent !important;
  border: 2px solid var(--yellow-color) !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1) !important;
  border-color: #ffc224 !important;
  background: #ffc224 !important;
  height: 12px !important;
  width: 12px !important;
}

.new-button {
  border: none;
  padding: 6px 20px;
  background-color: var(--yellow-color) !important;
  color: black;
  border-radius: 100px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  box-shadow: 4px 6px 0px 0px #050071;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--color-primary);
    box-shadow: 0px 0px 0px #050071;
    /* color: white; */
  }
}

.MuiDrawer-paperAnchorDockedLeft::-webkit-scrollbar {
  display: none !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgba(228, 228, 228, 0.2),
    0px 4px 5px 0px rgba(228, 228, 228, 0.2),
    0px 1px 10px 0px rgba(228, 228, 228, 0.2) !important;
}

.filter_check {
  position: relative;
  cursor: pointer;
}

.filter_check:before,
.filter_check2:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid var(--color-primary);
  border-radius: 3px;
  background-color: white;
}
.filter_check:checked:before {
  background-color: var(--color-primary);
}

.filter_check:checked:after,
.filter_check2:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-radius: 3px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.filter_check2 {
  position: relative;
  cursor: pointer;
}

.filter_check2:before {
  border: 2px solid #767676;
}
.filter_check2:checked:before {
  background-color: var(--yellow-color);
  border: 2px solid var(--yellow-color);
}
