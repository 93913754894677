.referral-container-area{
    width: 100%;
    min-height: 40vh;
    height: auto;
    /* background-color: #0D131D; */
    background-color: #FAFAFA;
    border-radius: 7px;
    padding: 20px 20px;
    /* box-shadow: 1px 5px 10px black; */
}
.referral-container-area .title-referral-card{
    font-weight: 600;
    letter-spacing: .3px;
    font-size: 14px;
}

.referral-container-area .ammount-area{
    font-weight: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: blue;
}

.referral-container-area .referral-progress-btn-area{
    padding: 10px 10px;
    border: 1.7px solid #0D131D;
    border-radius: 7px;
    width: fit-content;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.referral-container-area .referral-progress-btn-area:hover{
    background-color: #0D131D;
    color: white;
    cursor: pointer;
    font-weight: 700;
    box-shadow: 2px 1px 10px rgba(45, 44, 44, 0.587);
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.referral-container-area .referral-link{
    padding: 3px 5px;
}
.referral-container-area .referral-link span.icon-referral-page{
    width: fit-content;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: blue;
    cursor: pointer;
    font-weight: 600;
}
.referral-container-area .referral-link span:hover{
    color: aliceblue;
}

.referral-social-link svg{
    font-size: 42px;
    padding: 10px;
    color: black;
}
.referral-social-link svg:hover{
    color: blue;
    background-color: aliceblue;
    border-radius: 50%;
    border: 2px solid blue;
    cursor: pointer;
}

.referral-q-a-item{
    width: 100%;
    /* border: 2px solid #0D131D; */
    /* border: 2px solid rgba(128, 128, 128, 0.201); */
    height: auto;
    min-height: 8vh;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s;
}

.referral-area-heading{
    min-height: 8vh;
}

.referral-q-a-heading{
    font-size: 18px;
    font-weight: 600;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    letter-spacing: 1px;
    cursor: pointer;
}

.referral-q-a-drop-icon svg{
    font-size: 32px;
    cursor: pointer;
}