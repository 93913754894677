.notification-title-heading-parent{
    font-size: large;
    font-weight: 500;
}

.date-time-for-parent-notification{
    font-size: 16px;
    font-weight: 500;
    color: blue;
}

.log-item-for-parent-notification{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}