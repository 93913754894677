.chat_sidebar,
.chat_container_main {
  background: var(--color-white);
  height: 68vh;
}
.chat_sidebar {
  overflow: hidden;
  overflow-y: auto;
}

/* modifie the scrollbar */
.chat_sidebar::-webkit-scrollbar {
  width: 5px;
}
.chat_sidebar::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182);
  border-radius: 10px;
}

.chat_head {
  color: var(--color-primary);
}
.chat_search_bar {
  background: rgb(231, 231, 231);
}
.chat_search_bar input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.chat_user_img,
.chat_container_user_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);

  > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.distance_user_image,
.self_user_image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);

  > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.online_dot {
  height: 10px;
  width: 10px;
  background: green;
  border-radius: 50%;
  position: absolute;
  right: 0%;
  bottom: 0%;
  border: 2px solid var(--color-white);
}
.online_status {
  color: green;
  font-size: 12px;
}

.chat_container {
  height: 51vh;
  overflow-y: auto;
  padding-bottom: 1rem;
  overflow-x: hidden;
  padding-right: 5px;
}

.chat_container::-webkit-scrollbar {
  width: 5px;
}
.chat_container::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182);
  border-radius: 10px;
}

/* .chat_container::-webkit-scrollbar {
  display: none;
} */
/* Style for user messages */
.distance_user_msg {
  background: #e8f5ff; /* Light blue background */
  color: var(--color-dark); /* Dark text color */
  display: flex;
  align-items: center;
}

/* Initially hide the arrow-down-more-option */
.arrow-down-more-option {
  display: none;
  cursor: pointer;
  position: relative;
}
.arrow-down-more-option .more-oprions-like-star-report {
  position: absolute;
  top: 100%;
  right: -20%;
  z-index: 111;
  width: 160px;
  height: auto;
  background-color: #444444b6;
  border-radius: 7px;
}

.arrow-down-more-option-1 {
  display: none;
  cursor: pointer;
  position: relative;
}
.arrow-down-more-option-1 .more-oprions-like-star-report-1 {
  position: absolute;
  top: 10%;
  left: 0%;
  z-index: 111;
  width: 160px;
  height: auto;
  background-color: #444444b6;
  border-radius: 7px;
}

.arrow-down-more-option
  .more-oprions-like-star-report
  .more-oprions-like-star-report-item {
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

/* When hovering over a user message, display the downward arrow */
.distance_user_msg:hover .arrow-down-more-option {
  display: initial;
  color: red; /* Change the color of the arrow */
}

.self_user_msg {
  background: var(--color-primary);
  color: var(--color-white);
  display: flex;
  align-items: center;
}

.self_user_msg:hover .arrow-down-more-option {
  display: initial;
}

.chat_input_container {
  position: absolute;
  width: 93%;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_input_container form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_input_container .chat-input-more-option {
  width: 40px;
  height: 40px;
  background-color: #38a169;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.more-options-list {
  position: absolute;
  bottom: 12%;
  left: 5%;
  width: 180px;
  height: auto;
  border-radius: 7px;
  background-color: #38a169;
}
.more-options-list .more-option-chat-item {
  width: 100%;
  height: 6vh;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  cursor: pointer;
}

.chat_input_container .chat-input-input {
  width: 80%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #f89999;
  padding: 10px 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.chat_input_container .chat-input-btn {
  width: auto 15%;
  min-width: 14%;
  height: 100%;
  background-color: burlywood;
  padding: 10px 7px;
  border: none;
  outline: none;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.chat_container form input[type="text"] {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.chat_container form input[type="submit"] {
  background: var(--color-primary);
  color: var(--color-white);
  padding: 9px 20px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.message_user_row {
  cursor: pointer;
}

/* ============= Empty container ========= */

.empty-state {
  width: 550px;
  margin: 40px auto;
  border-radius: 4px;
}

.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__icon img {
  width: 170px;
}

.empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}

.empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
}

.credit a {
  color: #444;
}

@media (max-width: 768px) {
  .chat_sidebar {
    height: 68vh;
    width: 100%;
    position: absolute;
    top: 11%;
    left: 0%;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
  }
  .chat_container_main {
    height: 68vh;
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    overflow: hidden;
    overflow-y: auto;
    transform: translateX(0%);
    transition: all 0.3s ease-in-out;
  }
  .chat_container_main.active {
    transform: translateX(110%) !important;
  }
  .chat_sidebar.active {
    transform: translateX(0%);
    margin-top: 9vmax;
  }
  .chat_input_container {
    background: var(--color-light);
    position: absolute;
    width: 85%;
    bottom: 2%;
  }

  .chat_menu {
    width: fit-content;
    position: absolute;
    top: 10%;
    left: 25%;
    z-index: 99999;
    transition: all 0.4s ease;
  }
  .chat_close_icon {
    position: absolute;
    left: 0%;
    top: 0%;
  }

  .hidden {
    scale: 0;
    transition: all 0.4s ease;
  }

  .empty-state {
    width: 292px !important;
  }
}
