.bookademo {
  overflow-x: hidden;

  .top_bar_book {
    margin-top: 5.2rem;
    height: 40vh;
    width: 100%;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }

  .book_form {
    width: 60%;
    background: #f7f7fa;

    input {
      box-shadow: none;
      padding: 10px;
    }
    textarea {
      box-shadow: none;
    }
  }
}
