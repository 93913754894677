.prederences-item-title{
    font-size: 18px;
    font-weight: 600;
}
.preferences-upcoming-meeting-title{
    font-size: 18px;
    font-weight: 600;
}
.TimePicker{
    background-color: transparent;
}

@media screen and (min-width: 768px) {
    .prefrences_responsive-upcoming {
        display: flex; 
        align-items: center;
        justify-content: flex-start;
    }
}

