.parent-complain-image{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 1.3px solid black;
    border-radius: 50%;
    >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
    }
}

.n-box-shadow{
    box-shadow: 1px 2px 10px grey;
}


@media screen and (max-width: 768px) {
    .parent-complaint-subject-heading-responsive {
        justify-content: flex-start !important;
        text-align: start !important;
        padding-top: 10px !important;
        background: #000;
    }
}
