.how-it-works-cards {
  width: 100%;
  height: 360px;
  border-radius: 10px;
  padding-bottom: 10px;
  .icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      height: 30px;
      width: 30px;
      color: white;
    }
  }
}
