/* -------------------------------- banner style ---------------------------------------------- */
.blog-page {
    overflow-y: hidden;
    user-select: none;
    padding: 0 6vmax;
}

.blog_banner_area {
    width: auto;
    height: 50vh;
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 50px;
}

.blog_banner_area .address_loaction {
    color: #1F1D0D;
    font-size: 13px;
    height: 7vh;
    text-transform: uppercase;
}

.blog_banner_area .blog-banner-title {
    font-size: 3.2rem;
    color: #1F1D0D;
    font-weight: 600;
    width: 45%;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: start;
}

.blog_categry_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.blog_categry_area .blog_tag_categrory {
    padding: 3px 12px;
    background-color: #F6F6F6;
    color: #1F1D0D;
    width: fit-content;
    border-radius: 7px;
    text-transform: uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.blog_categry_socieal_link_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
}

.blog_categry_socieal_link_area .blog_social_link_icons {
    padding: 10px;
    border-radius: 50%;
    background-color: var(--color-primary);
}
.blog_categry_socieal_link_area .blog_social_link_icons:hover{
    box-shadow: 2px 3px 10px grey;
    opacity: .7;
}

.blog_categry_socieal_link_area .blog_social_link_icons svg {
    font-size: 22px;
    color: #1F1D0D;
}

.blog-comment-user-box {
    width: 100%;
    height: auto;
    background-color: #F6F6F6;
    padding: 20px 30px;
    border-radius: 7px;
    transition: all .3s ease
}

.blog-comment-user-box {
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.blog-comment-user-box:hover {
    /* transform: scale(1.1); */
    cursor: pointer;
    /* margin-left: 10px; */
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.3); /* Adjusted box shadow */
}

.blog-comment-user-box .blog-comment-image {
    width: 80px;
    height: 80px;
    border-radius: 7px;
}

.blog-comment-user-box .blog-comment-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: contain;
}

.blog-comment-user-box .blog_comment_like_icon {
    padding: 5px;
    border-radius: 50%;
}

.blog-comment-user-box .blog_comment_like_icon:hover,
.blog-comment-user-box .blog_comment_like_icon:hover svg {
    background-color: grey;
    color: #F6F6F6;
    cursor: pointer;
}

.blog-comment-user-box .blog_comment_like_icon svg {
    font-size: 32px;
    font-weight: 500;
    color: #1F1D0D;
    background-color: transparent;
    border-radius: 50%;
}

.blog-comment-text-area {
    width: 100%;
    height: 40vh;
    background-color: #F6F6F6;
    border-radius: 7px;
}

.blog-comment-text-area textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 7px;
    background-color: transparent;
    color: black;
    padding: 7px 10px;
}

.blog-comment-text-area textarea::placeholder {
    color: black;
}

.blog_comment_submit_button {
    width: 100%;
    background-color: #1F1D0D;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 7px;
    padding: 10px 20px;
    cursor: pointer;
}

.arrowUp_blog_icon svg {
    font-size: 28px;
    font-weight: 600;
}

.blog-suggest-artical-img {
    display: block;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.blog-suggest-artical-img img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.blog-tag {
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #F6F6F6;
    color: #1F1D0D;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.blog_tag_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.blog-artican-image {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.blog-artican-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}


.blackdot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: black;
}

.blog-artical-item {
    position: relative;
}

.blog-artican-image {
    position: relative;
}

.blog-artican-image img {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
}

.image-border::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: black;
    transition: width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.blog-artical-item:hover .image-border::before {
    width: 90%;
    left: 0;
}

.blog-artical-item .blog-artical-area-label {
    position: absolute;
    top: 3%;
    right: 5%;
    width: fit-content;
    padding: 3px 5px;
    background-color: #f5f5f5;
    border-radius: 3px;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    z-index: 1111;
}

.blog-artical-page-heading {
    font-weight: 700;
}

.bloge-page-1-input {
    width: 75%;
    height: 6vh;
    /* border: 2px solid rgb(47, 45, 45); */
    border-radius: 7px;
    overflow: hidden;
}

.bloge-page-1-input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    background: transparent !important;
}

.bloge-page-1-button {
    width: calc(100% - 75%);
    height: 6vh;
    background-color: var(--yellow-color);
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 255px;
    cursor: pointer;
    padding: 10px 20px;
}

.bloge-page-1-button:hover {
    box-shadow: 2px 3px 10px black;
}

.count-box {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid antiquewhite;
}

.count-box:hover {
    border: none;
    color: #000000;
    opacity: .5;
    background-color: var(--yellow-color);
    cursor: pointer;
    box-shadow: 2px 3px 10px black;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.count-box.blog-pagination-active {
    color: #000000;
    background-color: var(--yellow-color);
}

/* ------------------------------------------ carreer area ------------------------------------------- */


.career-area {
    width: auto;
    height: auto;
    overflow-y: hidden;
}

.career-area .blog-poster-img {
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.items-menu {
    display: flex !important;
    align-items: start !important;
    justify-content: flex-start !important;
    gap: 10px;
}

.items-cares-sub-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #1F1D0D;
    font-size: 15px;
    font-weight: 600;
}

.bload_heading {
    color: #1F1D0D;
    font-weight: 800;
    font-size: 22px !important;
}

.bload_heading-a{
    color: #1F1D0D;
    font-weight: 800;
font-size: 18px !important;
}
.image_second_area {
    width: 100%;
    height: auto;
}


/* ------------------------------------------ carreer area ------------------------------------------- */

/* Media queries for responsiveness */
@media (max-width: 768px) {
       .blog-page {
    padding: 0 2vmax !important;
}
    .blog_banner_area {
        height: 40vh;
        padding: 10px;
        margin-top: 10vh;
    }

    .blog_banner_area .blog-banner-title {
        width: 100%;
        font-size: 2rem;
        line-height: 40px;
    }

    .career-area .blog-poster-img {
        width: 100%;
        height: 40vh;
        overflow: hidden;
    }
}


@media (min-width: 768px) and (max-width: 1080px) {

 
    .blog_banner_area {
        height: 40vh;
        padding: 10px;
        margin-top: 10vh;
    }

    .blog_banner_area .blog-banner-title {
        width: 80%;
        font-size: 2rem;
        line-height: 40px;
    }

    .career-area .blog-poster-img {
        width: 100%;
        height: 60vh;
        overflow: hidden;
    }
}