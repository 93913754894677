/* Scrollbar design for webkit browsers */
.overflow-auto-thum::-webkit-scrollbar {
  width: 8px; /* Set scrollbar width */
  height: 3px; /* Set scrollbar height for horizontal scrolling */
}

/* The track (background of the scrollbar) */
.overflow-auto-thum::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners */
}

/* The handle (scroll thumb) */
.overflow-auto-thum::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll handle */
  border-radius: 10px; /* Rounded corners */
}

/* Change the handle color on hover */
.overflow-auto-thum::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade on hover */
}
