@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


/* Custom Scrollbar Styling */
.custom-scroll-bar::-webkit-scrollbar {
  width: 2px; /* For vertical scrollbars */
  height: 2px; /* For horizontal scrollbars */
}

.custom-scroll-bar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background color of the track */
  border-radius: 5px; /* Optional: to make the track rounded */
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Optional: to make the thumb rounded */
}

.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}


body {
  margin: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

/* admin -dashboard  rightdrawer */
.rightdrawer-admin-panel {
  position: fixed;
  top: 0;
  right: -24vw; /* Initially hide the drawer outside of the viewport */
  z-index: 142512;
  background-color: #f3f1eb;
  width: 20vw;
  height: 100vh;
  animation-duration: 0.5s; /* Animation duration */
  animation-timing-function: ease; /* Animation timing function */
}

.rightdrawer-admin-panel.open {
  right: 0; /* Slide the drawer into the viewport */
  animation-name: slideInRight; /* Apply slide-in animation */
}

.PhoneInputInput {
  border: none;
  margin-left: 10px;
}

.send-otp-text-parent-reg {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  color: blue;
  cursor: pointer; /* If you want it to look clickable */
}

@keyframes slideInRight {
  from {
    right: -20vw; /* Start position */
  }
  to {
    right: 0; /* End position */
  }
}

.rightdrawer-admin-panel .inner-notification-drawer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 7px 10px;
}

.rightdrawer-admin-panel .inner-notification-drawer .close-btn-notification {
  position: absolute;
  top: 3%;
  left: -15%;
  background-color: #ffd32b;
  width: 40px;
  height: 40px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightdrawer-admin-panel .inner-notification-drawer .notification-box {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.094);
  border-radius: 7px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 7px 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .rightdrawer-admin-panel {
    width: 100%;
    right: -100vw;
    height: 100% !important;
    padding: 0;
  }

  .rightdrawer-admin-panel.open {
    right: 0;
  }

  .rightdrawer-admin-panel .inner-notification-drawer .close-btn-notification {
    left: 1%;
    top: 1%;
  }
}

.submenu-class:hover {
  background-color: transparent !important;
}

.parent {
  position: relative;
  display: inline-block;
  height: fit-content;
  width: fit-content;
  overflow: hidden;
}

/* .admin-1-on-1-switch-top{
  position: absolute !important;
  top: -1% !important;
  right: -2% !important;
} */

.live-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
}

.live-indicator.blink {
  animation: fade-in-out-animation 2s linear infinite;
}

@keyframes fade-in-out-animation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

