.file-card {
  width: 150px;
  text-align: center;
  background-color: #f9f9f9;
  transition: transform 0.2s;
}

.file-card:hover {
  transform: translateY(-5px);
  background-color: #f0f0f0;
}

.file-icon {
  color: #6c757d;
}

.file-name {
  font-size: 14px;
  color: #333;
  margin: 0;
  word-break: break-word;
}

.download-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #007bff;
}

.download-btn:hover {
  color: #0056b3;
}
