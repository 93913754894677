/* ========================= Navbars =========================  */
nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;

  .links {
    font-size: 18px;
  }

  .logo {
    img {
      height: 60px;
      width: 60px;
    }
    a {
      text-decoration: none;
      color: #333;
      h4 {
        font-weight: bold;
      }
    }
  }

  .part2 {
    ul {
      li a {
        text-decoration: none;
        color: #333;
        font-weight: bold;
        position: relative;

        &:hover {
          color: var(--color-primary);
        }
      }
    }

    .dropdown-cus {
      .dropdown-content {
        position: absolute;
        width: 220px;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease;

        li {
          margin-bottom: 10px;
          padding: 11px 15px;
          position: relative;
          transition: all 0.5s ease;

          &::after {
            content: "";
            position: absolute;
            height: 0%;
            width: 3px;
            background: var(--color-primary);
            top: 0;
            left: 0;
            transition: all 0.2s ease;
          }

          &:hover::after {
            height: 100%;
          }
          &:hover {
            background: rgb(233, 233, 233);
          }
        }
      }
    }
  }

  .part3 {
    .search {
      padding: 5px 10px;
      border: 1px solid #8d8c9f;
      border-radius: 100px;
      width: fit-content;
      input {
        outline: none;
        border: none;
      }
    }

    button {
      border: none;
      padding: 6px 20px;
      background-color: var(--yellow-color);
      color: black;
      border-radius: 100px;
      font-weight: 500;
      position: relative;
      z-index: 2;
      box-shadow: 4px 6px 0px 0px #050071;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--color-primary);
        box-shadow: 0px 0px 0px #050071;
        color: white;
      }
    }
  }
}

.menu {
  display: none;
}

.dropdown-content.active {
  height: 300px !important;
  transition: all 0.3s ease;
  z-index: 9999;
}

.aside {
  display: none;
}

@media (max-width: 950px) {
  nav {
    padding: 0 1rem;
    .part2 {
      display: none;
    }

    .menu {
      display: block;
      position: absolute;
      right: 10px;
      top: 32px;
      z-index: 1000;
      cursor: pointer;

      svg {
        font-size: 30px;
      }
    }

    .aside {
      display: block;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      width: 0rem;
      background-color: white;
      top: 0;
      right: 0;
      overflow: hidden;
      z-index: 9999;
      transition: all 0.3s ease;

      ul {
        height: 100%;
        padding-left: 60px;
        padding-top: 100px;
        li {
          margin: 40px 0;
          a {
            text-decoration: none;
            color: black;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }

      .dropdown-cus {
        height: 30px;
        overflow: hidden;
        transition: all 0.3s ease;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        svg {
          font-size: 30px;
        }
      }
    }
  }

  .dropdown-cus.active {
    height: 310px !important;
    transition: all 0.3s ease;
  }

  .aside.active {
    width: 17rem;
    transition: all 0.3s ease;
  }
}

/* ========================== Hero =========================== */
.hero {
  width: 100%;
  height: 100vh;
  padding-top: 4rem;
  background-color: #f7f8fd;
  /* background: linear-gradient(to right, #F7FF65, #fefff7); */
  position: relative;

  .img1 {
    position: absolute;
    bottom: 5%;
    left: 6%;
    opacity: 0.5;
    animation: move2 3s linear infinite;
  }
  .img2 {
    position: absolute;
    top: 15%;
    left: 6%;
    animation: rotate 10s linear infinite;
  }
  .img3 {
    position: absolute;
    bottom: 7%;
    right: 5%;
    animation: move 3s linear infinite;
  }
  .img4 {
    position: absolute;
    top: 10%;
    right: 10%;
    animation: move2 3s linear infinite;
  }
  .slide {
    height: 76%;
    width: 98%;
    /* position: relative; */

    .slide1,
    .slide2,
    .slide3 {
      margin: 0 3rem;
      h5 {
        text-transform: uppercase;
        letter-spacing: 4px;
        position: relative;
      }
      p {
        font-size: 18px;
      }
      .pricing_section {
        h1 {
          color: var(--color-primary);
          font-weight: bold;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          span:nth-child(1) {
            font-size: 30px;
            font-weight: 400;
            color: gray;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: gray;
              top: 50%;
              left: 0;
              transform: translateY(100%);
            }
          }

          span:nth-child(2) {
            font-size: 14px;
            background: var(--secondary-color);
            color: white;
            padding: 3px 7px;
            border-radius: 3px;
          }
        }
      }
      .hero_btn1 {
        border: none;
        padding: 7px 20px;
        z-index: 99;
      }
      .hero_btn1_1 {
        background-color: var(--color-primary);
        color: white;
        position: relative;
        z-index: 2;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          background-color: #3500c6;
          top: 0;
          left: 0;
          z-index: -1;
          transition: all 0.5s;
        }

        &:hover::after {
          width: 100%;
        }
      }
      .hero_btn1_2 {
        background-color: var(--secondary-color);
        color: white;
        position: relative;
        z-index: 2;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          background-color: #d2005b;
          top: 0;
          left: 0;
          z-index: -1;
          transition: all 0.5s;
        }

        &:hover::after {
          width: 100%;
        }
      }
      .banner_img {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .arrow {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1.2px solid rgba(102, 102, 102, 0.502);
      z-index: 99999;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 20px;
        color: rgba(102, 102, 102, 0.502);
      }
    }
    .left_arrow {
      top: 50%;
      left: 1%;
      transform: translateY(-50%);
    }
    .right_arrow {
      top: 50%;
      right: 1%;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 768px) {
  .hero {
    height: auto;
    padding-top: 5rem;
    padding-bottom: 3rem;
    .slide {
      width: 100%;

      .slide1,
      .slide2,
      .slide3 {
        margin: 0 1rem;
      }
      .arrow {
        display: none;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes move2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ========================== Hero2 =========================== */

.hero2 {
  padding-top: 9rem;
  width: 100%;
  position: relative;
  background: linear-gradient(to right, #f5f3f9, #f7f6f9);
  overflow: hidden;
  z-index: 2;
  border-bottom: 2px solid #e7e7e7;

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    height: 12px !important;
    width: 12px !important;
    background: transparent;
    border: 2px solid red;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #ffc224;
    border-color: #ffc224;
    height: 12px !important;
    width: 12px !important;
  }

  .top,
  .bottom {
    position: absolute;
    top: 50%;
    right: -4%;
    height: 300px;
    width: 300px;
    z-index: -1;
    background: linear-gradient(to bottom, #e9dfff 0%, #f0f3ff 100%);
    border-radius: 50%;
    /* make blur */
    filter: blur(50px);
  }

  .bottom {
    top: 20%;
    left: 0%;
  }

  .banner_svg {
    position: absolute;
    top: 0%;
    left: 0;
    width: 20%;
    height: 100%;
    z-index: -1;
  }

  .col1_hero {
    h6 {
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 700;
      color: #050071;
    }
    h1 {
      font-size: 44px;

      span {
        position: relative;
        color: white;
        padding: 0 13px 0 20px;
        z-index: 1;
        line-height: 1.5;
        > svg {
          position: absolute;
          left: 0;
          top: 3px;
          width: 100%;
          height: 100%;
          z-index: -1;
          color: var(--yellow-color);
        }
      }
    }

    .play {
      transition: all 0.3s ease;
      .play_icon {
        transition: all 0.3s ease;
        height: 45px;
        width: 45px;
        border: 1px solid #050071;
        background: var(--yellow-color);
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 3px 4px 0px 0px #02002b;

        > svg {
          font-size: 25px;
          color: #050071;
        }
      }
      > p {
        line-height: 1.3;
        transition: all 0.3s ease;
      }
      &:hover .play_icon {
        box-shadow: 0px 0px 0px 0px #02002b;
      }
      &:hover > p {
        color: var(--yellow-color);
      }
    }
  }
  .col2_hero {
    svg {
      color: var(--yellow-color);
    }
    .girl_img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;
      top: 80%;
      left: 57%;
      scale: 1.4;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 768px) {
  .hero2 {
    .col1_hero {
      h1 {
        line-height: 1.6;
        font-size: 2.2rem;
        span {
          color: var(--yellow-color);
          padding: 0;
          svg {
            display: none;
          }
        }
      }
    }
    .col2_hero {
      svg {
        height: 360px !important;
        width: 360px !important;
      }

      .girl_img {
        left: 62%;
      }
    }
  }
}

/* ============================== About ==============================  */

.about {
  height: 100vh;
  width: 100%;
  /* background-color: #f7f8fd; */
  position: relative;
  overflow-x: hidden;
  z-index: 2;
  padding-top: 4rem;

  .top,
  .bottom {
    position: absolute;
    top: -3%;
    right: -4%;
    height: 300px;
    width: 300px;
    z-index: -1;
    background: linear-gradient(to bottom, #f1ebff 0%, #f7f8fd 100%);
    border-radius: 50%;
    /* make blur */
    filter: blur(30px);
  }

  .bottom {
    top: 30%;
    left: 0%;
  }

  .about__img3 img {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
    animation: move 3s linear infinite;
  }
  .about__img1 img {
    height: 70%;
    width: 70%;
    object-fit: contain;
    z-index: 2;
    position: relative;
  }
  .about__img2 img {
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 3;
  }

  .content {
    background-color: #ffffff;
    width: fit-content;
    padding: 10px 10px;
    position: absolute;
    bottom: 10%;
    left: 0%;
    z-index: 2;
    animation: move2 3s linear infinite;

    &::after {
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: var(--color-primary);
      top: 0%;
      left: 0;
    }
  }

  .col2 {
    .badge_c {
      color: var(--color-primary);
      background-color: #e7deff;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 100px;
    }
    h1 {
      span {
        position: relative;
        z-index: 2;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 10%;
          background-color: var(--secondary-color);
          bottom: 20%;
          left: 0;
          z-index: -1;
          transform: translateY(100%);
        }
      }
    }
    > p {
      position: relative;
      color: gray;

      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: var(--color-primary);
        top: 0%;
        left: -2%;
      }
    }
    .about_li {
      transition: all 0.3s ease;
      .tick {
        height: 20px;
        width: 20px;
        transition: all 0.3s ease;

        svg {
          color: var(--color-primary);
          transition: all 0.3s ease;
        }
      }

      &:hover .tick {
        background: var(--color-primary);
      }
      &:hover .tick svg {
        color: white;
      }
    }
  }
}

/* ============================== About2 ==============================  */

.about2 {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 4rem 0;

  .col1_about {
    position: relative;

    .img {
      height: 44vw;
      width: 67%;
      background: #f3f3f3;
      position: relative;
      /* make capsul */
      border-radius: 400px;
      box-shadow: 10px 10px 0px 0px #8c8c8c7e;
      z-index: 2;
      overflow: hidden;

      .shape-one {
        position: absolute;
        left: 20%;
        top: 11%;
        z-index: -1;

        > svg {
          color: var(--yellow-color);
        }
      }

      .boy_img img {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 85%;
        width: 85%;
        object-fit: cover;
      }
    }

    .tag1 {
      background: white;
      position: absolute;
      top: 80%;
      left: 10%;
      z-index: 3;
      padding: 10px 20px;
      transform: translateY(-50%);
      box-shadow: -6px 6px 0px 0px #8c8c8c7e;
      animation: move 3s linear infinite;
      p {
        span {
          color: var(--color-primary);
        }
      }

      img {
        width: 120px;
        object-fit: contain;
      }
    }

    .tag2 {
      background: white;
      position: absolute;
      top: 10%;
      right: 10%;
      z-index: 3;
      padding: 10px 20px;
      transform: translateY(-50%);
      box-shadow: 6px 6px 0px 0px #8c8c8c7e;
      animation: move2 3s linear infinite;

      .icon {
        width: 50px;
        height: 50px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-primary);
        color: white;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -o-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-shadow: 4px 4px 0px #efeefe;
        -moz-box-shadow: 4px 4px 0px #efeefe;
        -ms-box-shadow: 4px 4px 0px #efeefe;
        -o-box-shadow: 4px 4px 0px #efeefe;
        box-shadow: 4px 4px 0px #efeefe;
        border: 1px solid #c5c1ff;

        > svg {
          vertical-align: middle;
          color: white;
        }
      }
    }
  }

  .col2_about {
    .badch_cu {
      background: #efeefe;
      border-radius: 60px;
      color: var(--color-primary);
      width: fit-content;
    }
    h1 {
      font-size: 40px;
      span {
        position: relative;
        color: white;
        padding: 0 13px 0 20px;
        z-index: 1;
        line-height: 1.6;
        > svg {
          position: absolute;
          left: 0;
          top: 3px;
          width: 100%;
          height: 100%;
          z-index: -1;
          color: var(--yellow-color);
        }
      }
    }

    .about2_lis {
      width: fit-content;

      .arrow {
        height: 30px;
        width: 30px;
        background: var(--yellow-color);
        border-radius: 50%;
        box-shadow: 3px 3px 0px 0px #8c8c8c7e;
        border: 1px solid #050071;
        transition: all 0.3s ease;
      }

      &:hover .arrow {
        box-shadow: 0px 0px 0px 0px #8c8c8c7e;
      }
    }
  }
}

@media (max-width: 768px) {
  .about2 {
    padding-top: 0;
    .col1_about {
      .tag2 {
        top: 0%;
      }
      .img {
        height: 100vw;
        width: 100vw;

        .shape-one {
          > svg {
            color: #fbe67b;
            height: 200px !important;
            width: 200px !important;
          }
        }
      }
    }
    .col2_about {
      h1 {
        span {
          font-size: 2.2rem;
        }
      }
    }
  }
}

/* ================================ Cources ================================ */

.cource,
.brain_bits2 {
  width: 100%;
  /* background: linear-gradient(to right, #f5f3f9, #f7f6f9); */
  overflow: hidden;

  .badch_cu {
    background: #efeefe;
    border-radius: 60px;
    color: var(--color-primary);
    width: fit-content;
  }

  .cources {
    width: 100%;
    transition: all 0.3s ease;

    .content {
      width: 100%;
    }

    .child {
      gap: 20px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      transition: all 0.3s ease;
      width: 25%;

      .course-cat {
        position: absolute;
        right: 0%;
        bottom: 0%;
      }

      .child_icon {
        height: 60px;
        width: 60px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        .img1 {
          height: 40px;
          width: 40px;
          transition: all 0.3s ease;
        }

        .img2 {
          height: 40px;
          width: 40px;
          position: absolute;
          transform: translateY(120%);
          transition: all 0.3s ease;
        }
      }
      background: white;
      color: #4a4a4a;
      cursor: pointer;
      padding: 15px 30px;

      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 0%;
        background-color: #04004a;
        top: 0%;
        left: 0;
        border-radius: 15px;
        transition: all 0.3s ease;
        z-index: -1;
      }

      &:hover::after {
        width: 100%;
        height: 100%;
      }
      &:hover {
        color: white;
      }
      &:hover .child_icon .img2 {
        transform: translateY(0%);
        /* put delay */
        transition-delay: 0.5s;
      }
      &:hover .child_icon .img1 {
        transform: translateY(-120%);
      }
    }
  }
}

/* .child.active {
  color: white !important;
  position: relative;
  transition: all 0.3s ease;
  background: var(--yellow-color);
} */
.card_c {
  border: 1px solid #8d8c9f;
  transition: all 0.3s ease !important;

  .heart {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #f2f0f5;
    position: absolute;
    top: 2%;
    right: 2%;
  }

  .cource_img {
    width: 100%;
    object-fit: cover;
  }

  button {
    background: #efeff2;
    transition: all 0.3s ease;
    border: 1px solid #8d8c9f;
    border-radius: 100px;

    &:hover {
      background: var(--color-primary);
      color: white;
    }
  }

  h6 {
    color: var(--color-primary);
  }

  h5 {
    font-size: 18px;
  }

  .user_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: gray;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .star {
    color: var(--yellow-color);
  }

  .bottom {
    border-top: 1px solid #8d8c9f;
  }

  .bottom p:nth-child(1) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: rgb(86, 86, 86);
      border-radius: 50%;
      top: 50%;
      right: -30%;
      transform: translateY(-50%);
    }
  }
  .bottom p:nth-child(2) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: rgb(86, 86, 86);
      border-radius: 50%;
      top: 50%;
      right: -20%;
      transform: translateY(-50%);
    }
  }

  &:hover {
    box-shadow: 6px 6px 0px 0px #8d8c9f88;
  }
}

@media (max-width: 768px) {
  .cource {
    .cources {
      height: auto;
      width: 100%;

      .content {
        width: 100%;
      }

      .child {
        width: 100%;
      }
    }
  }
}

/* ============================= Mentor  ============================= */
.mentor {
  width: 100%;
  margin-top: 3rem;

  .col1_mentor {
    .badch_c {
      background: #efeefe;
      border-radius: 60px;
      color: var(--color-primary);
      width: fit-content;
    }
  }

  .col2_mentor {
    z-index: 2;
    position: relative;
    .back {
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, #f5f3f9, #f7f6f9);
      /* border-radius: 3rem; */
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }
    .event_shap {
      position: absolute;
      bottom: -6%;
      right: 0;
      animation: move2 2s linear infinite;
    }
    .card {
      height: 100%;
      width: 100%;
      background: white;
      position: relative;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 6px 6px 0px 0px #8c8c8c7e;
      }

      .heart {
        position: absolute;
        bottom: 15px;
        right: 15px;

        img {
          height: 25px;
          width: 25px;
          object-fit: contain;
        }
      }

      .user_image {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: #f2f2f2;
        flex-shrink: 0;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      span {
        font-size: 11px;
      }
    }
  }
}

/* ====================== Joyful ====================== */

.badch_c {
  background: #ebeaff;
  border-radius: 60px;
  color: var(--color-primary);
  width: fit-content;
}
.whyChoose_us_main {
  width: 100%;
}

.lottie_img {
  top: 116.56px;
  left: -72px;
}

.whyChoose_us_cards {
  width: 100%;
  position: relative;
}

.whyChoose_us_card {
  width: 20% !important;
}

.whyChoose_us_card p {
  font-size: 14px;
}

.whyChoose_img {
  width: 158px;
  height: 100px;
  top: 116.56px;
  left: -72px;
}

@media (max-width: 768px) {
  .whyChoose_us_card {
    width: 100% !important;
    margin-bottom: 3vmax;
  }
}

/* ========================== Testimonial ========================== */

.testi {
  width: 100%;
  position: relative;
  background: linear-gradient(to right, #f5f3f9, #f7f6f9);

  .badch_c {
    background: #ebeaff;
    border-radius: 60px;
    color: var(--color-primary);
    width: fit-content;
  }

  .card_t {
    position: relative;
    .inner {
      position: relative;
      .triangle {
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-top: 15px solid white;
        z-index: 3;
        top: 100%;
        left: 5%;
      }
    }

    .user_image {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background: #f2f2f2;
      flex-shrink: 0;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .quot {
      height: 30px;
      width: 30px;
      background: var(--yellow-color);
      position: absolute;
      z-index: 3;
      top: -5%;
      left: -3%;
    }
  }
}

/* ====================== Get In Touch styling ====================== */

.getintouch_main {
  background: #01001d;
  color: white;
  /* height: 1vh; */
  margin-top: 3rem;
}

.get_in_touch_form {
  background: white;
  color: #01001d;
  width: 70%;
  transition: all 0.3s ease;
  box-shadow: 8px 8px 0px 1px rgb(69, 69, 69);
  margin-top: 5vmax;
}

.get_in_touch_form input {
  padding: 15px 10px;
  border: 1px solid gray;
}

.get_in_touch_form textarea {
  padding: 15px 10px;
}

.get_in_touch_form button {
  padding: 15px 10px;
  background: #01001d;
  border: 1px solid gray;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border: none;
  width: 100%;
  overflow: hidden;
  height: 55px;
}

.get_in_touch_form button .text2 {
  transform: translateY(110%);
  transition: all 0.3s ease;
}

.get_in_touch_form button:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}

.get_in_touch_form button:hover .text1 {
  transform: translateY(-170%);
  transition: all 0.3s ease;
}

.get_in_touch_form button:hover .text2 {
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.shap_img2_1 {
  position: absolute;
  top: -8%;
  right: 20%;

  > img {
    height: 90%;
    width: 90%;
    object-fit: cover;
  }
}

.shap_img2_2 {
  position: absolute;
  bottom: 0%;
  left: -25%;

  > img {
    height: 90%;
    width: 90%;
    object-fit: cover;
    transform: rotate(-90deg);
  }
}

@media (max-width: 768px) {
  .get_in_touch_form {
    width: 100%;
  }
}

/* ====================== How we work styling ====================== */

.how_img {
  height: 250px;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.how_img:hover {
  box-shadow: 10px 10px 0px 0px #8c8c8c7e;
  transform: translate(-5px, -5px);
}

.video_icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid black;
}

.video_icon:hover {
  background: var(--color-primary);
  border: none;
  color: white;
}

/* ============================ Course Main ========================= */

.allCources {
  width: 100%;
  background: linear-gradient(to right, #f5f3f9, #f7f6f9);
  /* background: white; */

  .badch_cu {
    background: #efeefe;
    border-radius: 60px;
    color: var(--color-primary);
    width: fit-content;
  }
  .serach_part {
    width: 100%;

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      input {
        padding: 10px 20px;
        border: 1px solid #8d8c9f;
        border-radius: 100px;
        width: 40%;
        outline: none;
      }

      button {
        padding: 10px 20px;
        background: var(--yellow-color);
        border: none;
        border-radius: 100px;
        color: black;
        font-weight: 500;
        margin-left: 10px;
        border: 1px solid black;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 4px 4px 0px 0px #8d8c9f;
      }
      &:hover {
        box-shadow: 0px 0px 0px 0px #8d8c9f;
      }
    }
  }

  .card_data {
    position: relative;
    .data_inner {
      cursor: pointer;
      position: relative;
      /* overflow: hidden; */

      p {
        font-size: 17px;
      }
      .dropdown_c {
        position: absolute;
        height: 0;
        width: 100%;
        background: white;
        top: 100%;
        left: 0%;
        z-index: 8;
        overflow: hidden;
        transition: all 0.3s ease;
      }
      .data_children {
        background: rgb(239, 239, 239);
        width: 100%;
        position: relative;
        transition: all 0.3s ease;

        &:hover {
          background: var(--color-primary);
          color: white;
        }
      }
    }
  }
}

.dropdown_c.active {
  height: auto !important;
}

@media (max-width: 768px) {
  .allCources {
    .serach_part {
      input {
        width: 80%;
      }
    }
  }
}

/* ====================== brainBits styling ====================== */

.brain_bits_row {
  padding: 0 8vmax;
}
.brain_bits_card {
  height: auto;
  border: 1.5px solid gray;
  box-shadow: 8px 8px 0px 1px #c6c6c6;
  margin-bottom: 2vmax;
}

.brain_bits_card_top {
  height: 15rem;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.brain_bits_card_bottom {
  height: 60%;
}

.brain_bits_right_arrow,
.brain_bits_left_arrow {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--yellow-color);
  cursor: pointer;
}

.brain_bits_right_arrow {
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
}
.brain_bits_left_arrow {
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .brain_bits_row {
    padding: 0 2vmax;
  }
}

/* ====================== Accordian styling ====================== */

.accordian_main {
  padding: 0 7vmax !important;
  transition: all 0.3s ease !important;
}

.accordian_custome {
  height: 50px;
  overflow: hidden;
  transition: all 0.3s ease !important;
}

.accordian_custome.active {
  height: auto !important;
}

.accordian_icons {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.row_line {
  height: 2px;
  width: 100%;
  background: #9d9d9d;
}

@media (max-width: 768px) {
  .accordian_main {
    padding: 0 3vmax !important;
  }
}

/* ====================== Footer styling ====================== */

.footer_top {
  width: 100%;
}
.footer_top_content {
  width: 70% !important;
  background: var(--yellow-color);
  padding: 5vmax 0;
  box-shadow: 8px 8px 0px 1px #c6c6c6;
}
.footer_top_content form {
  width: 100%;
  display: flex;
}

.footer_top_content form input {
  padding: 15px 10px;
  width: 30%;
  border: 2px solid #01001d;
}

.footer_top_content form button {
  padding: 15px 10px;
  width: 20%;
  background: #01001d;
  color: white;
  border: none;
  cursor: pointer;
}

.footer_bottom {
  background: #01001d;
  margin-top: -5vmax !important;
  padding-top: 12vmax !important;
  color: white;
}

.social_media {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--yellow-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.social_icon_inner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: var(--yellow-color);
  cursor: pointer;
  transition: all 0.3s ease;
}
.social_media:hover {
  background: transparent;
  border: 2px solid var(--yellow-color);
  padding: 3px;
}

@media (max-width: 768px) {
  .footer_top_content {
    width: 100% !important;
  }
  .footer_top_content form {
    flex-direction: column;
  }
  .footer_top_content form input {
    width: 100%;
    margin-bottom: 3vmax;
  }
  .footer_top_content form button {
    width: 100%;
  }
}

.video_part {
  /* height: 140vh; */
  width: 100%;
  position: relative;
  overflow: hidden;

  .badch_c {
    background: #efeefe;
    border-radius: 60px;
    color: var(--color-primary);
    width: fit-content;
  }

  .video {
    height: 100%;
    width: 100%;

    .video_v {
      height: 60vh;
      width: 100%;

      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-position: center;
      }
    }

    .card {
      /* box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3); */
      /* background-color: #fff; */
      /* border-radius: 8px; */
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: #475f7b;
      border: none;
      overflow: hidden;
      width: 100%;
    }
    .rc-accordion-toggle {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      justify-content: space-between;
      background-color: #f2f4f7;
      transition: 0.3s;
    }
    .rc-accordion-toggle.active {
      background: linear-gradient(to right, var(--yellow-color), #ffdf8f);
      background: linear-gradient(to right, var(--color-primary), #736fdb);
    }
    .rc-accordion-toggle.active .rc-accordion-icon {
      transform: rotate(180deg);
      color: #fff;
    }
    .rc-accordion-card {
      /* border: 1px solid #ddd; */
      /* border-radius: 5px; */
      /* margin-bottom: 10px; */
      overflow: hidden;
      border-bottom: 1px solid #c2c2c2;
    }
    .rc-accordion-card:last-child {
      margin-bottom: 0;
    }
    .rc-accordion-title {
      font-weight: 500;
      font-size: 14px;
      position: relative;
      margin-bottom: 0;
      color: #475f7b;
      transition: 0.3s;
    }
    .rc-accordion-toggle.active .rc-accordion-title {
      color: #fff;
    }
    .rc-accordion-icon {
      position: relative;
      top: 2px;
      color: #475f7b;
      transition: 0.35s;
      font-size: 12px;
    }
    .rc-accordion-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 15px;
    }
    .rc-accordion-body p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #727e8c;
    }
    .rc-collapse {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height 0.35s ease;
    }
    .rc-collapse.show {
      height: 75vh !important;
      background: #f7f5f1;
    }
  }
}

/* ============================= Counter ============================== */
.counter {
  height: 45vh;
  width: 100%;
  position: relative;

  .counter_inner {
    height: 100%;
    width: 100%;
    background: var(--color-primary);
    /* background: #01001D; */
    position: relative;
    overflow: hidden;

    .counter-col {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 3px;
        background: linear-gradient(to bottom, white, var(--color-primary));
        top: 0%;
        right: 0%;
      }
    }

    .counter-col:last-child::after {
      display: none;
    }

    .shape-one {
      position: absolute;
      bottom: 0%;
      right: 0%;
    }
    .fact_img {
      position: absolute;
      bottom: 0%;
      right: 0%;
    }
  }
}
