.profile-parent-page-image-area{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    >img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        object-position: top;
    }
}