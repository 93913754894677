.img-box-certificate {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    padding: 7px 10px;

    >img {
        object-fit: contain;
        border-radius: 7px;
    }
}

.gift-page-cources-list-area {
    width: 100%;
    height: auto;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    flex-direction: column;
}

.gift-page-cources-list-area-item {
    width: 100%;
    height: 7vh;
    border-radius: 10px;
    border: 1px solid #E4CFCF;
    background: rgba(217, 217, 217, 0.49);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    gap: 10px;
    >svg{
        color: red;
        font-size: 22px;
    }
}
.gift-cource-checkout{
    background: rgba(217, 217, 217, 0.49);
}
.gift-cource-checkout-item{
    >svg{
        font-size: 22px;
        color: red;
    }
}