/* Globals  */
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

/* Variables  */
:root {
  --color_Blue: #3f93f1;
  --color_Dark1: #1e1e1e;
  --color_Dark2: #252527;
  --color_Light1: #fff;
  --color_Light2: #fff;
}


.bx-grid-alt svg {
  color: black !important;
}
/* =============== Sidebar =============== */
.admin_dashboard .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  /* background: linear-gradient(90deg, #ffd32b 35%, #ffdc5a 100%); */
  background: white;
  transition: all 0.5s ease;
  z-index: 100;
  border-right: 1px solid rgba(77, 77, 77, 0.238);
}

.admin_dashboard .sidebar.close {
  width: 78px;
}

.admin_dashboard a {
  text-decoration: none;
}

/* --------- Logo ------------ */
.admin_dashboard .logo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  color: black;
  transition: all 0.5s ease;
}

.admin_dashboard .logo-box:hover {
  color: rgb(38, 38, 38);
}

.admin_dashboard .logo-box i {
  font-size: 30px;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  transition: all 0.5s ease;
}

.admin_dashboard .sidebar.close .logo-box i {
  transform: rotate(360deg);
}

.admin_dashboard .logo-name {
  font-size: 15px;
  font-weight: 600;
}

/* ---------- Sidebar List ---------- */
.admin_dashboard .sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.admin_dashboard .sidebar-list::-webkit-scrollbar {
  display: none;
}

.admin_dashboard .sidebar-list li {
  transition: all 0.3s ease;
}

.admin_dashboard .sidebar-list li:hover {
  background-color: rgba(206, 206, 206, 0.238);
}

.admin_dashboard .sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.admin_dashboard .sidebar-list li.active .title {
  background-color: rgba(206, 206, 206, 0.238);
}

.admin_dashboard .sidebar-list li.active .bxs-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.admin_dashboard .sidebar-list li .title .link {
  display: flex;
  align-items: center;
}

.admin_dashboard .sidebar-list li .title .link:hover{
  background-color: rgba(206, 206, 206, 0.238) !important;
}

.admin_dashboard .sidebar-list li .title i {
  height: 50px;
  min-width: 48px;
  text-align: center;
  line-height: 50px;
  color: var(--color_Light1);
  font-size: 20px;
}

.admin_dashboard .sidebar-list li .title .name {
  font-size: 15px;
  font-weight: 400;
  /* color: var(--color_Light1); */
  color: black;
}

/* ---------------- Submenu ------------- */
.admin_dashboard .sidebar-list li .submenu {
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.admin_dashboard .sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: rgba(206, 206, 206, 0.238);
}

.admin_dashboard .submenu .link {
  color: var(--color_Light2);
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
}

.admin_dashboard .submenu .link:hover {
  color: #fff;
}

.admin_dashboard .submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.admin_dashboard .sidebar.close .logo-name,
.admin_dashboard .sidebar.close .title .name,
.admin_dashboard .sidebar.close .title .bxs-chevron-down {
  display: none;
}

.admin_dashboard .sidebar .sidebar-list li.dropdown .title{
  width: 100%;
}
.admin_dashboard .sidebar.close .sidebar-list {
  overflow: visible;
}

.admin_dashboard .sidebar.close .sidebar-list li {
  position: relative;
}

.admin_dashboard .sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
}

.admin_dashboard .sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: rgba(206, 206, 206, 0.238);
}
.admin_dashboard .sidebar.close .sidebar-list li:hover{
  background-color: rgba(206, 206, 206, 0.238) !important;

}

.admin_dashboard .sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
  color: var(--color_Light1);
}

/* =============== Home Section =============== */
.admin_dashboard .home {
  position: relative;
  background-color: var(--color_Light1);
  left: 260px;
  width: calc(100% - 260px);
  height: 100%;
  padding-bottom: 250px;
  transition: all 0.5s ease;
}

.admin_dashboard .sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.admin_dashboard .home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  /* width: fit-content; */
  cursor: pointer;
}

.admin_dashboard .home .toggle-sidebar i {
  font-size: 35px;
  color: var(--color_Dark1);
  margin-left: 15px;
}

.admin_dashboard .home .toggle-sidebar .text {
  font-size: 25px;
  color: var(--color_Dark1);
  font-weight: 600;
}

.admin_dashboard .profile {
  justify-content: right;
  height: 30px;
  width: 50px;
}

.admin-link-hover-none:hover{
  background-color: transparent;
}