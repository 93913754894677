.home-faq-container{
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, #f0ecfa, #f7f7f9, #f7ebf9);
}
.home-faq-img-area{
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.faq-page-link{
    color: blue;
    cursor: pointer;
    font-size: 22px;
}

@media only screen and (max-width: 768px) {
    .home-faq-container{
        height: auto;
    }
}
