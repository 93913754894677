.resorce-parent-item{
    width: 100%;
    height: 7vh;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border: 1px solid grey;
    box-shadow: 1px 2px 10px rgba(128, 128, 128, 0.648);
}
.resorce-parent-item .name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.resorce-parent-item .name svg{
    color: red;
    font-size: 22px;
}
.resorce-parent-item svg{
    font-size: 22px;
}