.settings-title-heading-parent{
    font-size: large;
    font-weight: 500;
}

.stting-parent-title{
    font-weight: bold;
}
.setting-date-picker-parent{
    width: 100% !important;
}