main {
  width: 80% !important;
  position: relative !important;
}

.parent_slider {
  border: 2px solid var(--yellow-color);
  width: 95%;
  min-height: 45vh;
  /* background: red; */

  .parent_slider_img {
    height: 44vh;
    width: 33vw;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

/* .swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
} */

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}

.parent_cards1 {
  width: 100%;

  .parent_cards1_top {
    > img {
      object-fit: cover;
    }
  }
}

.parent_resp_row1 {
  max-width: 81vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parent_resp_row1,
.parent_resp_row2 {
  max-width: 76vw;
}

.parent_user_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.parent_card2_top {
  height: 28vh;
  width: 100%;
  > img {
    height: 100%;
    width: 95%;
    object-fit: cover;
  }
}

.parent_right_arrow,
.parent_left_arrow,
.parent_left_arrow2,
.parent_right_arrow2 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--yellow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 99999; */

  >svg{
    color: rgb(0, 0, 0);
  
  }
}

.parent_right_arrow {
  right: 2%;
}

.parent_right_arrow2 {
  right: 2%;
}

.parent_left_arrow,
.parent_left_arrow2 {
  left: -2%;
}

.card1_icons {
  position: absolute;
  top: 25%;
  right: 6%;
}

.start_btn_card1{
  background: var(--color-primary);
  border-radius: 10px;
  position: absolute;
  bottom: 9%;
  right: 5%;
  color: white;
}

.parent_accept,
.parent_decline {
  height: 30px;
  width: 30px;
  border-radius: 6px;
  cursor: pointer;
}

.parent_decline {
  background: #fde0e0;

  > svg {
    color: red;
  }
}

.parent_accept {
  background: #e0fde0;
  > svg {
    color: green;
  }
}

.gift_btn{
  padding: 7px 14px;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  background: transparent;
  font-weight: bold;
  color: var(--color-primary);
  cursor: pointer;
}

.freeDemo_right_arrow1,
.freeDemo_left_arrow1,
.freeDemo_right_arrow2,
.freeDemo_left_arrow2,
.freeDemo_right_arrow3,
.freeDemo_left_arrow3 {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  background: var(--yellow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;

  >svg{
    color: rgb(0, 0, 0);
  }
}

.freeDemo_right_arrow1,
.freeDemo_right_arrow2,
.freeDemo_right_arrow3 {
  right: 5%;
}

.freeDemo_left_arrow1,
.freeDemo_left_arrow2,
.freeDemo_left_arrow3 {
  left: -1%;
}

@media (max-width: 768px) {
  .parent_resp_row1,
  .parent_resp_row1,
  .parent_resp_row2 {
    max-width: 90vw !important;
  }
  .parent_slider {
    width: 83vw !important;
  }
  .parent_slider_img {
    height: 44vh;
    width: 100vw !important;
  }
}
