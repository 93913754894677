.search-main-cards {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.00);
    /* background: linear-gradient(129deg, #F7FF62 27.75%, rgba(217, 217, 217, 0.00) 98.48%); */
    /* box-shadow: 8px 9px 7px 0px rgba(28, 26, 26, 0.60); */
    padding: 20px 20px;
    transform: scale(1);
}
.search-main-cards:hover {
    transform: scale(1.1);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s;
}
