/* Default styles */
.admin-main {
  user-select: none;
}

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sign-in-title {
  font-weight: 600;
  margin-top: 30px;
}

.login-container-semi-p {
  width: 70%;
  height: auto;
  border: 2px solid black;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start !important;
  gap: 20px;
  /* box-shadow: 2px 3px 10px black; */
  padding: 7vh 0px;
}

.login-container-semi-p .input {
  width: 80%;
  height: 8vh;
  border: 2px solid black;
  border-radius: 7px;
  overflow: hidden;
}

.login-container-semi-p .otp-container {
  width: 80%;
  height: 8vh;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  gap: 5px;
}

.login-container-semi-p .otp-container .otp {
  width: calc(100% / 6);
  height: 100%;
  border: 2px solid black;
  border-radius: 7px;
  overflow: hidden;
}

.login-container-semi-p .otp-container .otp input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 26px;
  text-align: center;
}

.login-container-semi-p .input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 15px;
  font-size: 18px;
}

.login-container-semi-p .remember-area {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-container-semi-p .phone-area {
  width: 74%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.login-container-semi-p .phone-area .contryCode {
  width: 20%;
  height: 100%;
  border: 2px solid black !important;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  background-color: red;
}

.login-container-semi-p .phone-area .phone-input {
  width: calc(100% - 20%);
  height: 100%;
  border-radius: 7px;
  border: 2px solid black;
}

.login-container-semi-p .phone-area .phone-input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 15px;
  font-size: 22px;
}

.login-container-semi-p .btn {
  width: 80%;
}

.login-container-semi-p .btn .login-btn {
  width: 100%;
  height: 7vh;
  background-color: #1f1d0d;
  color: #f1f1f0;
  border: none;
}

.login-n-link {
  color: #2258a4;
  font-weight: 700;
  cursor: pointer;
}

.login_animation_text2 {
  opacity: 0;
  display: block;
  transition: all 0.3s ease;
}

.login_animation_text1 {
  margin-top: 7px;
}

.login-container-semi-p .btn:hover .login_animation_text1 {
  opacity: 0;
  margin-top: 0px;
  transform: translateY(-90%);
}

.login-container-semi-p .btn:hover .login_animation_text2 {
  opacity: 1;
  /* Show the second text */
  transform: translateY(-115%);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .login-container-semi-p {
    width: 100%;
    height: auto;
    padding: 20px;
    padding-top: 40px;
  }

  .login-container-semi-p .input,
  .login-container-semi-p .remember-area,
  .login-container-semi-p .otp-container,
  .login-container-semi-p .phone-area,
  .login-container-semi-p .btn {
    width: 100%;
  }
}

@media (max-width: 1080px) {
  .parent-main-container {
    /* padding: 10px 20px; */
  }

  .login-container-semi-p {
    width: 100%;
    height: auto;
    padding: 20px;
    padding-top: 40px;
  }

  .sign-in-title {
    font-size: 2.375rem;
  }

  .login-container-semi-p .input,
  .login-container-semi-p .remember-area,
  .login-container-semi-p .otp-container,
  .login-container-semi-p .phone-area,
  .login-container-semi-p .btn {
    width: 100%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
