.teacher-profile-rating > svg{
    color: #ffd32d;
}

.TeacherProfile-title-responsive{
    padding-left: 2%;
    padding-top: 3%;
}
.TeacherProfile-peragraph-responsive{
    padding-left: 5%;
    padding-top: 2%;
}

.teacher-profile-items-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    >svg{
        color: blue;
    }
}

.teacter-profile-top-review-itme{
    width: 100%;
    height: auto;
    border-radius: 7px;
    /* border: 1px solid red; */
}
.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header{
    width: 100%;
    height: 10vh;
    /* background-color: red; */
    display: flex;
}

.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header .left{
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}
.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header .left .profile-image-for-review{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: green;
    overflow: hidden;
    >img{
        width: 100%;
        height: 100%;
    }
}
.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header .left .profile-name-for-review{
    width: fit-content;
}
.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header .left .profile-name-for-review .name{
    font-weight: bold;
    font-size: 16px;
}

.teacter-profile-top-review-itme .teacter-profile-top-review-itme-header .right{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teacter-profile-top-review-itme-menu-option{
    width: 100%;
    height: 100%;
    background-color: red;
}

.this-is-review-star{
    color: #ffd32d;
}

.teacher-profile-review-date{
    color: black !important;
}

.teacher-button{
    background-color: var(--color-primary) !important;
    color: rgb(255, 255, 255) !important;
}


.teater-profile-image-subjcet{
    width: 100%;
    height: 25vh;
    overflow: hidden;
    >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media screen and (max-width:768) {
    .TeacherProfile-peragraph-responsive{
        padding-left: 2%;
        padding-top: 2%;
    }
    .TeacherProfile-title-responsive{
        padding-top: 2%;
        padding: 0 !important;
    }
}