/* Default styles */
.admin-main{
    user-select: none;
}
.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sign-in-title {
    font-weight: 600;
    margin-top: 30px;
}

.login-container-semi {
    width: 30vw;
    height: auto;
    border: 2px solid black;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    box-shadow: 2px 3px 10px black;
}

.login-container-semi .input {
    width: 80%;
    height: 8vh;
    border: 2px solid black;
    border-radius: 7px;
    overflow: hidden;
}

.login-container-semi .input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 15px;
    font-size: 18px;
}

.login-container-semi .remember-area {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-container-semi .btn {
    width: 80%;
}

.login-container-semi .btn .login-btn {
    width: 100%;
    height: 7vh;
    background-color: #1F1D0D;
    color: #F1F1F0;
    border: none;
}

.login-n-link {
    color: #2258a4;
    font-weight: 700;
    cursor: pointer;
}

.login_animation_text2 {
    opacity: 0;
    display: block;
    transition: all 0.3s ease;
}

.login_animation_text1 {
    margin-top: 7px;
}

.login-container-semi .btn:hover .login_animation_text1 {
    opacity: 0;
    margin-top: 0px;
    transform: translateY(-90%);
}

.login-container-semi .btn:hover .login_animation_text2 {
    opacity: 1;
    /* Show the second text */
    transform: translateY(-115%);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .login-container-semi {
        width: 100%;
        height: auto;
        padding: 20px;
        padding-top: 40px;
    }

    .login-container-semi .input,
    .login-container-semi .remember-area,
    .login-container-semi .btn {
        width: 100%;
    }
}

@media (max-width: 1080px) {
    .login-container-semi {
        width: 100%;
        height: auto;
        padding: 20px;
        padding-top: 40px;
    }

    .login-container-semi .input,
    .login-container-semi .remember-area,
    .login-container-semi .btn {
        width: 100%;
    }
}