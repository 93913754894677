.help_main {
  overflow-x: hidden;

  .top_bar_help {
    margin-top: 5.2rem;
    height: 40vh;
    width: 100%;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }

  .help_inner {
    .bg_help {
      background: #f7f7fa;

      .icon {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: var(--color-primary);
        flex-shrink: 0;
      }
    }

    input {
      box-shadow: none;
      padding: 10px;
    }
    textarea {
      box-shadow: none;
    }
  }
}
