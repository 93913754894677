.StudentManagent-cards{
    width: 100%;
    height: 20vh;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.student-managment-teacher-view-btn{
    color: var(--color-primary) !important;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.student-managent-student-profile{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 1px solid grey;
    overflow: hidden;
    padding: 3px;
    >img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
    }
}